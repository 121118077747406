import { useState } from 'react';
import './App.css';
import Logo from './components/Logo';
import FluidAnimation from './components/FluidAnimation';
import FolderList from './components/FolderList';
import CircularText from './components/CircularText';
import { images } from './components/FluidAnimation';

function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleRotateImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  return (
    <div className="App">
      <CircularText />
      <div className="hero-section">
        <FluidAnimation currentImageIndex={currentImageIndex} />
        <div className={`mask ${
          currentImageIndex === 0 ? 'mask-athens' : 
          currentImageIndex === 1 ? 'mask-murakami' : 
          'mask-empire'
        }`}>
          <header className="App-header">
            <div className="logo-container">
              <Logo onRotateImage={handleRotateImage} />
            </div>
          </header>
        </div>
      </div>
      <div className="folder-section">
        <FolderList />
      </div>
    </div>
  );
}

export default App;
import React from 'react';

const CircularText = () => {
  return (
    <div className="circular-text-container">
      <svg viewBox="0 0 200 200" width="100%" height="100%">
        <defs>
        <path
            id="circle"
            d="M 100,100 m -150,0 a 150,150 0 1,1 300,0 a 150,150 0 1,1 -300,0"  
            />
        </defs>
        <text className="circular-text rotating">
          <textPath href="#circle">
            Nothing was the same • Nothing was the same • Nothing was the same • Nothing was the same • Nothing was the same • Nothing was the same •
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default CircularText;
import { BaseItem } from '../../types';

export const songItems: BaseItem[] = [
  {
    name: "Passionfruit.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/Passionfruit.mp3" 
  },
  {
    name: "King_of_Everything.mp3",
    size: "100",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/King_of_Everything.mp3"
  },
  {
    name: "Darling_I.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/Darling_I.mp3"
  },
  {
    name: "Cameras.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/cameras_drake.mp3"
  },
  {
    name: "I_Love_You.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/I_Love_You.mp3"
  }
];